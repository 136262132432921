/*  This file is part of ql_rest, a free-software/open-source library
    for utilization of QuantLib over REST */

import BusinessDatePicker from './components/BusinessDatePicker'
import LabeledNumericInput from './components/LabeledNumericInput'

import StockPanel from './components/StockPanel'
import VolPanel from './components/VolPanel'
import SmilePanel from './components/SmilePanel'
import PositionsPanel from './components/PositionsPanel'
import ExposurePanel from './components/ExposurePanel'
import GreeksPanel from './components/GreeksPanel'
import SingleStrikePricer from './components/SingleStrikePricer'
import Dropdown from './components/Dropdown'
import Ticket from './components/Ticket'


import PricerHelper from './helpers/PricerHelper'
import QuantLibHelper from './helpers/QuantLibHelper'
import SessionHelper from './helpers/SessionHelper'

import 'react-datepicker/dist/react-datepicker.css';


import { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap/';
import { compareAsc, format, parseISO } from 'date-fns'
import uuid from 'react-uuid'

import { GoogleLogin, GoogleOAuthenProvider, useGoogleLogin} from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { jwtDecode } from "jwt-decode";

import './App.css';

function App() {

  const volPanelRef = useRef();
  const smileRef = useRef();
  const greeksPanelRef = useRef();

  const userSessionVolRef = useRef({});

  const [valuationDate, setValuationDate] = useState(new Date());
  const [defaultTicker, setDefaultTicker] = useState(undefined);
  const [exerciseDates, setExerciseDates] = useState();
  const [exerciseDate, setExerciseDate] = useState();

  const [exerciseTypes, setExerciseTypes] = useState();
  const [exerciseType, setExerciseType] = useState();

  const [pricerSetup, setPricerSetup] = useState();

  const [pricingModels, setPricingModels] = useState();
  const [pricingModel, setPricingModel] = useState(undefined);

  const [pricingToken, setPricingToken] = useState([]);
  const [straddlePricingToken, setStraddlePricingToken] = useState({});

  const [activeStockTicker, setActiveStockTicker] = useState();
  const [workingPrice, setWorkingPrice] = useState();
  const [updateUnderlyingPrice, setUpdateUnderlyingPrice] = useState();
  const [pricingDisabled, setPricingDisabled] = useState();
  const [greeksDisabled, setGreeksDisabled] = useState();
  const [error, setError] = useState();

  const [portalSymbols, setPortalSymbols] = useState([]);
  const [stockPrices, setStockPrices] = useState([]);

  const [volUpdate, setVolUpdate] = useState();
  //const [origVolData, setOrigVolData] = useState();
  const [volChartData, setVolChartData] = useState();
  const [volGreekData, setVolGreekData] = useState();
  const [activeInstrumentVols, setActiveInstrumentVols] = useState();
  const [interpolVolData, setInterpolVolData] = useState({});
  const [selectedStrike, setSelectedStrike] = useState();
  const [selectedStrikeWithGreeks, setSelectedStrikeWithGreeks] = useState();
  const [pricedStrikeWithGreeks, setPricedStrikeWithGreeks] = useState();
  const [riskFreeRate, setRiskFreeRate] = useState(0.25);
  const [pricerURL, setPricerURL] = useState();
  const [latency, setLatency] = useState();

  const [interpolTypes, setInterpolTypes] = useState();
  const [interpolType, setInterpolType] = useState();
  const [enableExtrapol, setEnableExtrapol ] = useState();

  const [userCredentials, setUserCredentials] = useState();
  const [positions, setPositions] = useState({});
  const [exposure, setExposure] = useState();
  const [marketData, setMarketData] = useState([]);
  const [logonResponse, setLogonResponse] = useState();
  const [tradeBooked, setTradeBooked] = useState();

  const calculatorReplyCounter = useRef();
  const calculatorReplyState = useRef();



  useEffect(() => {

    var request = {}
    PricerHelper.get_data('get_pricer_setup', request, (pricer_setup) => { setPricerSetup(pricer_setup) });

  }, []);

  useEffect(() => {

    if (pricerSetup === undefined )
      return;

    var exerciseTypes = pricerSetup['Exercises']
    var interpolation = pricerSetup['Interpolations']

    setExerciseTypes(exerciseTypes)
    setInterpolTypes(interpolation)

  }, [pricerSetup]);

  useEffect(() => {

    if (stockPrices === undefined )
      return;

      var marketData = {}

      stockPrices.forEach((item, i) => { marketData[item['Symbol']] = item; });

      if (updateUnderlyingPrice !== undefined )
      {
        marketData[updateUnderlyingPrice['Symbol']]['Price'] = parseFloat(updateUnderlyingPrice['Price']);
      }

      setMarketData(marketData);

  }, [stockPrices, updateUnderlyingPrice]);


  useEffect(() => {

    if (exerciseTypes === undefined )
      return;

    setExerciseType(exerciseTypes[1]);

  }, [exerciseTypes, pricerSetup]);


  useEffect(() => {

    if (exerciseType === undefined || pricerSetup === undefined )
      return;

    var models = pricerSetup['Models'][exerciseType.value];

    setPricingModels(models);

  }, [exerciseType, pricerSetup]);



 useEffect(() => {

   if ( activeStockTicker === undefined || activeInstrumentVols === undefined || exerciseDate === undefined || pricingModel === undefined )
   {
     return
   }

   console.log(riskFreeRate)

   //var price_request = {}

  //var vols_and_payoffs = {}
   //vols_and_payoffs['call'] = []
   //vols_and_payoffs['put'] = []

   var request_id = uuid();

   var volData = PricerHelper.get_exercise_date_vols(activeInstrumentVols)

   var vols_and_payoffs = [{'call':[],'put':[]},{'call':[],'put':[]},{'call':[],'put':[]},{'call':[],'put':[]}]

   var instrument_count = 0
   for ( var vol in volData )
   {
     var calculator_id = instrument_count%4
     var expiry_date_iso = PricerHelper.expiry_date_iso(exerciseDate.value)

     var option_details = {}
     option_details['request_id'] = request_id
     option_details['Underlying'] = activeStockTicker.Price
     option_details['Strike'] = volData[vol].Strike
     option_details['div_rate'] = activeStockTicker.dividendRate
     option_details['RiskFreeRate'] = riskFreeRate/100.0
     option_details['exerciseType'] = exerciseType.value
     option_details['valuationDate'] = valuationDate
     option_details['expiry_date_iso'] = expiry_date_iso
     option_details['expiry_date_vol'] = exerciseDate.value
     option_details['model'] = pricingModel.value

     if ( volData[vol].call_vol != undefined )
     {
       option_details['OptionType'] = 'Call'
       option_details['vol'] = volData[vol].call_vol

       var call = QuantLibHelper.get_option_termstructure(exerciseType.value, option_details)
       vols_and_payoffs[calculator_id]['call'].push(call)
     }

     if ( volData[vol].put_vol  != undefined )
     {
       option_details['OptionType'] = 'Put'
       option_details['vol'] = volData[vol].put_vol

       var put = QuantLibHelper.get_option_termstructure(exerciseType.value, option_details)
       vols_and_payoffs[calculator_id]['put'].push(put)
     }

     instrument_count = instrument_count + 1
   }
   setPricingDisabled(true);
   setSelectedStrikeWithGreeks(undefined);
   setSelectedStrike(undefined);
   setVolUpdate(undefined);

   calculatorReplyCounter.current = 0
   calculatorReplyState.current = {}

   vols_and_payoffs.forEach((item, i) => {

        repriceInstrumentGroup(valuationDate, i, item);

   });


 }, [exerciseDate, activeInstrumentVols, valuationDate, activeStockTicker, riskFreeRate, updateUnderlyingPrice, exerciseType, pricingModel]);

 const repriceInstrumentGroup = (valuationDate, groupId, instrumentGroup) =>
 {
   var price_request = {}
   price_request["request_id"] = uuid();
   price_request["portal"] = "OPTIONS_PORTAL"
   price_request["operation"] = "PRICE"
   price_request["business_date"] = format(valuationDate, "yyyy-MM-dd")
   price_request['vols_and_payoffs'] = instrumentGroup

   PricerHelper.submit_request(price_request, (pricingToken) => {
        //setTimeout(() => {
          console.log("Submitted out : " + JSON.stringify(pricingToken))
          //setPricingToken(pricingToken);
          calculatorReplyState.current[pricingToken.request_id] = pricingToken;
          const length = Object.keys(calculatorReplyState.current).length;
          console.log("Number of elements in Dict " + length)
          if ( length == 4 )
            setStraddlePricingToken(calculatorReplyState.current)
        //}, 200 * (groupId+1) );
   });
 }

 useEffect(() => {

   if (straddlePricingToken == undefined || calculatorReplyState.current == undefined )
      return;

      //var pending_requests = {}

      Object.keys(straddlePricingToken).map((pricer_key, index) => {
        console.log("Lets check : " +pricer_key + ":" + JSON.stringify(straddlePricingToken[pricer_key]))
        //setPricingToken(straddlePricingToken[pricer_key])
        PricerHelper.check_request(straddlePricingToken[pricer_key], (pricingResults) =>
        {
            console.log("Result State: " + pricingResults.state);
            if (pricingResults.state == 2)
            {
              var greeks = PricerHelper.get_greeks(pricingResults);
              greeksPanelRef.current.update_greeks(greeks);

              delete calculatorReplyState.current[pricingResults.request_id]

              calculatorReplyCounter.current = calculatorReplyCounter.current + 1

              if (calculatorReplyCounter.current == 4)
              {
                setPricingDisabled(false);
                setGreeksDisabled(false);
              }

            } else {
              //calculatorReplyState.current[pricingResults.request_id] = pricingResults
              var pending_requests = {...calculatorReplyState.current}
              setStraddlePricingToken(pending_requests)
            }
        });
      });

      //setStraddlePricingToken(pending_requests)

      /*const length = Object.keys(pending_requests).length;

      if ( length == 0 )
      {
        //setError(pricingResults.error);
        setPricingDisabled(false);
        setGreeksDisabled(false);
      } else {
        setStraddlePricingToken(pending_requests)*/
    //  }

 }, [straddlePricingToken]);

 useEffect(() => {

   if (pricingToken == undefined)
      return;

   console.log("Checking Pricing Request : " + JSON.stringify(pricingToken))

   PricerHelper.check_request(pricingToken, (pricingResults) =>
   {
     if ( pricingResults.state == 1 && pricingResults['operation'] == 'VOL_CALC' )
     {
       var pricer_re_request = {};
       pricer_re_request["request_id"] = pricingResults.request_id;
       pricer_re_request["url"] = pricingResults.url;
       console.log("Re-Checking Pricing Request : " + JSON.stringify(pricer_re_request))
       setPricingToken(pricer_re_request);
       return;

     } else {

       if ( pricingResults['operation'] == 'PRICE')
       {
         calculatorReplyCounter.current = calculatorReplyCounter.current + 1

         if (calculatorReplyCounter.current == 4 )
         {
           if (selectedStrike !== undefined )
           {
             greeksPanelRef.current.flash_stike(selectedStrike);
           }
         }

         var greeks = PricerHelper.get_greeks(pricingResults);
         greeksPanelRef.current.update_greeks(greeks);

       } else if (pricingResults['operation'] == 'VOL_CALC') {


         Object.keys(pricingResults['vols']).forEach((symbol, i) => {
           var interpolated_vols = pricingResults['vols'][symbol]
           userSessionVolRef.current[symbol]['interpolated_vols']=interpolated_vols
         });

        const updated_interpol_vol_data = JSON.parse(JSON.stringify(userSessionVolRef.current));

         setInterpolVolData(updated_interpol_vol_data)

       } else {
         setError(pricingResults.error);
         setPricingDisabled(false);
         setGreeksDisabled(false);
         console.log(pricingResults);
       }
       setPricingToken(undefined);
     }
   }, 100);


 }, [pricingToken, selectedStrike]);

    useEffect(() => {

      if ( interpolVolData != undefined && activeStockTicker != undefined && exerciseDate != undefined && interpolVolData[activeStockTicker.Symbol] != undefined )
      {
        var active_instrument_vol_data = interpolVolData[activeStockTicker.Symbol]

        if ( volUpdate != undefined )
        {
          var strike = volUpdate.data.Strike;

          var intepolated_vol_data = userSessionVolRef.current[activeStockTicker.Symbol]['interpolated_vols'][exerciseDate.value]

          if ( intepolated_vol_data['diviser'] != undefined )
            strike = strike * parseFloat(intepolated_vol_data['diviser'])

          const updated_interpol_vol_data = JSON.parse(JSON.stringify(intepolated_vol_data))

          updated_interpol_vol_data['calls'][strike] =  parseFloat(volUpdate.data.call_vol)
          updated_interpol_vol_data['puts'][strike] = parseFloat(volUpdate.data.put_vol)

          userSessionVolRef.current[activeStockTicker.Symbol]['interpolated_vols'][exerciseDate.value] = updated_interpol_vol_data
          const interpol_vol_data = JSON.parse(JSON.stringify(userSessionVolRef.current));

          setInterpolVolData(interpol_vol_data);
          setVolChartData(updated_interpol_vol_data);
          setActiveInstrumentVols(updated_interpol_vol_data);
          setVolUpdate(undefined);
        } else {

          const vol_chart_data = JSON.parse(JSON.stringify(interpolVolData[activeStockTicker.Symbol]['interpolated_vols'][exerciseDate.value]));

          setVolChartData(vol_chart_data);
          setActiveInstrumentVols(vol_chart_data);
        }
      }

    }, [interpolVolData, activeStockTicker, volUpdate, exerciseDate]);


  useEffect(() => {

    if ( interpolType != undefined && exerciseDate != undefined )
    {
      var ticker_vols = userSessionVolRef.current[activeStockTicker.Symbol]['original_vols']
      var ticker_vols_expiry = ticker_vols[exerciseDate.value]
      setVolChartData(ticker_vols_expiry);

      const vol_greeks_data = PricerHelper.get_exercise_date_vols(ticker_vols_expiry)
      setVolGreekData(vol_greeks_data);

      var price_request = {}

      var request_id = uuid();
      price_request["request_id"] = request_id
      price_request["portal"] = "OPTIONS_PORTAL"
      price_request["operation"] = "VOL_CALC"

      var interpol_request = QuantLibHelper.get_interpolation_request(request_id, ticker_vols, interpolType.value)
      price_request["request"] = {}
      price_request["request"][activeStockTicker.Symbol] = interpol_request

      PricerHelper.submit_request(price_request, (pricingToken) => { setPricingToken(pricingToken); });
    }

  }, [interpolType, exerciseDate, activeStockTicker]);


  useEffect(() => {

    if ( portalSymbols === undefined )
      return;

    var ticker_request = {};
    ticker_request['tickers'] = [];
    portalSymbols.forEach(symbol => ticker_request['tickers'].push(symbol['Symbol']));
    ticker_request['tickers'].push('risk_free_rates');

    var results = PricerHelper.get_data('get_latest_prices', ticker_request, (tickers_with_prices) => {
          var updated_market_data = [];

            portalSymbols.forEach(symbol => {

              if ( tickers_with_prices[symbol['Symbol']].iex_data === undefined)
              {
                symbol['Price'] = tickers_with_prices[symbol['Symbol']].price;
              } else {
                symbol['Price'] = tickers_with_prices[symbol['Symbol']].iex_data.latestPrice;
                symbol['PriceChange'] = tickers_with_prices[symbol['Symbol']].iex_data.change;
                symbol['PriceChangePercent'] = (tickers_with_prices[symbol['Symbol']].iex_data.changePercent * 100.0).toFixed(2);
              }

              symbol['dividendRate'] = tickers_with_prices[symbol['Symbol']].dividendRate;

              updated_market_data.push(symbol);

            } );

            if (portalSymbols.length > 0 )
            {
              setDefaultTicker('THIS WILL SELECT FIRST ROW');
            }

            var risk_free_rate = tickers_with_prices['risk_free_rates']['1Y'];

            setRiskFreeRate(risk_free_rate);
            setStockPrices(updated_market_data);
        });

  }, [portalSymbols]);

  useEffect(() => {

    if ( userCredentials != undefined )
    {
      SessionHelper.submit_login(userCredentials, (logon_response) => { setLogonResponse(logon_response['data']) });
      setPositions(undefined);
      setExposure(undefined);
    }

  }, [userCredentials]);

  useEffect(() => {

    if ( logonResponse != undefined  && interpolType != undefined)
    {
        var position_request = {}
        position_request = {}
        position_request['email'] = logonResponse['email']
        position_request['jti'] = logonResponse['jti']

        SessionHelper.get_positions(position_request, (positions_in) =>
          {
            if ( positions_in !== undefined && positions_in.positions !== undefined )
            {
              setInterpolVolData(undefined);

              var positions_out = {}
              positions_in.positions.forEach((position, i) => {
                positions_out[position['symbol']] = position;
              });

              setPositions(positions_out);

              var price_request = {}
              var request_id = uuid();
              price_request["request_id"] = request_id
              price_request["portal"] = "OPTIONS_PORTAL"
              price_request["operation"] = "VOL_CALC"
              price_request["request"] = {}

              positions_in.vols.forEach((vols_in, i) => {

                if ( userSessionVolRef.current[vols_in['symbol']] === undefined )
                {
                  var interpol_request = QuantLibHelper.get_interpolation_request(uuid(), vols_in['price_data'], interpolType.value)
                  price_request["request"][vols_in['symbol']] = interpol_request

                  var exercises_dates = []
                  Object.keys( vols_in['price_data'] ).forEach((item, i) => {
                    var exercise = {};
                    exercise['value'] = item;
                    exercise['label'] = item;
                    exercises_dates.push(exercise);
                  });

                  var user_session_vols = {}
                  user_session_vols['exercise_dates'] = exercises_dates
                  user_session_vols['original_vols'] = vols_in['price_data']
                  user_session_vols['interpolated_vols'] = vols_in['price_data']

                  userSessionVolRef.current[vols_in['symbol']] = user_session_vols
                }

              });

              PricerHelper.submit_request(price_request, (pricingToken) => { setPricingToken(pricingToken); });
            }
          });
    };

  }, [logonResponse, tradeBooked, interpolType ]);


  const VolPanelSetup = {
    pricingData:{},
    curve_price_callback : ( priced_curve ) => {},
    pricing_start : () => { setPricingDisabled(true) },
  };

  const responseMessage = (response) => {

      const USER_CREDENTIAL = jwtDecode(response.credential);
      setUserCredentials(USER_CREDENTIAL);

    };

    const errorMessage = (error) => { console.log(error); };

    const logged_in_div =
      <div style={{textAlign:'left'}}>
        <Row>
          <Col>
            {userCredentials == undefined ? null : userCredentials.name}
          </Col>
        </Row>
        <Row>
          <Col style={{fontSize:'10px'}}>
            {userCredentials == undefined ? null : userCredentials.email}
          </Col>
        </Row>
      </div>;


  return (
    <div>
         <nav>
            <h3 className="nav--logo_text">
              <div style={{paddingTop:'10px'}}>
                Vanilla Options
                <div style={{display: 'flex', justifyContent: 'left', fontSize:'14px', fontWeight:'500', paddingTop:'0px'}}>
                  <a className="App-link"  href="mailto:mike.kipnis@gmail.com">Support</a>
                </div>
              </div>
            </h3>

            <Row style={{opacity:'80%'}}>
              <Col className="nav--logo_text">
                <div style={{fontSize:'14px',  textAlign: 'right', textWrap: 'nowrap'}}>
                {
                  userCredentials == undefined ? <div style={{width:100}}>Sign-in with</div> : logged_in_div
                }
                </div>
              </Col>
              <Col>
              <GoogleOAuthProvider clientId="66097256498-ifgfagl8lb2n2sv95n47d9r19qatjipc.apps.googleusercontent.com">
                <div style={{margin:'0px'}}>
                  <GoogleLogin onSuccess={responseMessage} onError={errorMessage} theme='filled_black' size='medium' type='icon'/>
                </div>
              </GoogleOAuthProvider>
              </Col>
            </Row>
          </nav>
          <div className="ag-theme-balham-dark">
          <Container fluid  style={pricingDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
          <Row>
            <Col  style={{marginTop:'10px', textAlign:'Right', marginLeft:'auto', marginRight:0 }}>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <div style={{margin :'10px', display:'flex', alignItems: 'flex-end', justifyContent:'flex-end', marginRight:10, fontSize:'15px'}}>
                        <BusinessDatePicker label='Valuation:' elementName='business_date' selected_date={valuationDate}
                          onValueChange={ (elementName, date) =>
                            {
                              setValuationDate(date);
                            } }/>
                      </div>
                    </Col>
                    <Col>
                      <div style={{margin :'10px', display:'flex', alignItems: 'flex-end', justifyContent:'flex-end', marginRight:10, fontSize:'15px'}}>
                        <LabeledNumericInput label="R.F.Rate:" value={riskFreeRate} elementName="riskFreeRate" width="60"
                          onChanged={(elementName, new_value)=>{setRiskFreeRate(new_value);}}
                          onChange= {(elementName, new_value)=>{setGreeksDisabled(true);}} postfix='%' step={0.025}/>
                      </div>
                    </Col>
                  </Row>
              <Row>
          <Col  style={{textAlign:'Left'}}>
            <StockPanel symbols={stockPrices} defaultTicker={defaultTicker}
              gridReadyCallback = { () => {
                  var intial_ticker_request = {}
                  PricerHelper.get_data('get_usto_tickers', intial_ticker_request, (symbols) => {
                      setPortalSymbols(symbols);
                      setStockPrices(symbols);
                      });
                    }
                }

              priceChangeCallback = { (updatePrice) =>
              {
                var updated_stock_price =  Object.assign({}, updatePrice.data);
                setUpdateUnderlyingPrice(updated_stock_price)
                //const updated_market_data = JSON.parse(JSON.stringify(marketData));
                //setMarketData(updated_market_data);
                //var updated_market_data = Object.assign({}, updatePrice.data);
                //setWorkingPrice(updated_stock_price.Price);
                //setWorkingDividendRate(updated_stock_price.dividendRate);
                //setActiveStockTicker(updated_stock_price);
              } }

              getVols = { (ticker) =>
              {
                var symbol_vol_request = {}
                var symbol_list = [ticker.Symbol,]  // This is the initial setup for a given symbol, so there should be only one symbol in the list
                symbol_vol_request['tickers'] = symbol_list

                setWorkingPrice(ticker.Price);
                setSelectedStrike(undefined)
                setSelectedStrikeWithGreeks(undefined)
                setPricedStrikeWithGreeks(undefined)
                //setInterpolVolData(undefined)
                setActiveStockTicker(ticker)
                setExerciseDate(undefined)
                //setOrigVolData(undefined)
                setVolChartData(undefined);
                setVolGreekData(undefined);
                setActiveInstrumentVols(undefined);

                if ( userSessionVolRef.current[ticker.Symbol] != undefined )
                {
                  var user_session_vols = userSessionVolRef.current[ticker.Symbol]
                  var exercise_dates = user_session_vols['exercise_dates']
                  setExerciseDates(exercise_dates)
                  setExerciseDate(exercise_dates[0])
                  return
                }

                var results = PricerHelper.get_data('get_latest_vols', symbol_vol_request, (latest_vols) => {

                      symbol_vol_request['tickers'].forEach((symbol, i) => {

                        var exercises_dates = []
                        var ticker_vols = latest_vols[symbol]

                        Object.keys( ticker_vols ).forEach((item, i) => {
                          var exercise = {};
                          exercise['value'] = item;
                          exercise['label'] = item;
                          exercises_dates.push(exercise);
                        });

                        var user_session_vols = {}
                        user_session_vols['exercise_dates'] = exercises_dates
                        user_session_vols['original_vols'] = ticker_vols
                        user_session_vols['interpolated_vols'] = ticker_vols

                        userSessionVolRef.current[symbol] = user_session_vols
                        setExerciseDates(exercises_dates)
                        setExerciseDate(exercises_dates[0])
                        return;
                      });
                  });
              }} />
              </Col>
            </Row>
          </Col>
          <Col>
          <Row>
                  <Col style={{marginTop:'5px', textAlign:'Right'}}>
                    <Dropdown label='Exercise Date:' elementName='Exercise' options={exerciseDates} selected_value={exerciseDate} onValueChange={ ( element, selected_option ) =>{
                      setActiveInstrumentVols(undefined)
                      setExerciseDate(selected_option)
                    } }/>
                  </Col>
                </Row>
                <Row>
                  <Col style={{marginTop:'8px', textAlign:'Right'}}>
                    <VolPanel volData={volChartData} setup={VolPanelSetup} marketPrice={activeStockTicker} workingPrice={workingPrice} ref={volPanelRef}
                      strikeSelectedCallback={ (strike) => {
                        setSelectedStrike(strike);
                      } }
                      volChangeCallback={ ( updated_vol ) =>{
                        setWorkingPrice(updated_vol.data.Strike);
                        console.log("----------> " + updated_vol);
                        setVolUpdate(updated_vol);
                      } }/>
                  </Col>
                </Row>
          </Col>
        </Row>
        <Row style={{textAlign:'center'}}>
          <div className="main--sub--title">
            (Double click to change the Price or Call/Put Volatility)
          </div>
        </Row>
      </Col>
      <Col>
      <Row>
      <Col style={{marginTop:'15px', width:'40%', display: 'left', justifyContent: 'left'}}>
      <Dropdown  label='Intepolation:' elementName='IntepolationType' options={interpolTypes} selected_value={interpolType} onValueChange={ ( element, selected_option ) => { setInterpolType(selected_option);} }/>
      </Col>
      {
        /*
      <Col tyle={{textAlign: 'left', width:'20%'}}>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'left', alignItems:'left', paddingRight:'10px', marginTop:'20px' }}>
            <input type="checkbox" checked={enableExtrapol} onChange={ () => { setEnableExtrapol(!enableExtrapol); }}/>
            <div style={{paddingLeft:'5px'}}>Extrapolate</div>
          </div>
      </Col>*/
      }
      <Col style={{display: 'right', justifyContent: 'right', paddingTop:'10px', textAlign:'right', color:'#ffffff', whiteSpace: 'nowrap'}}>
            <div style={{display:'flex', alignItems: 'flex-end', justifyContent:'flex-end'}}>
            <div style={{fontWeight:'700', fontSize:'25px', paddingTop:'5px', paddingRight:'50px', opacity:'0.8'}}>
              { activeStockTicker != undefined && exerciseDate != undefined ? activeStockTicker.Name  : "" }
            </div>
            <div style={{fontWeight:'300', fontSize:'22px', paddingRight:'10px', opacity:'0.70'}}>
              { /* stockTicker != undefined && exerciseDate != undefined ? exerciseDate.label : ""*/ }
            </div>
            </div>
      </Col>
      </Row>
        <Row>
        <Col>
          <div style={pricingDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
            <SmilePanel stockTicker={activeStockTicker}
              volData={volChartData}
              excerciseLabel={exerciseDate}
              strikeSelectedCallback={ (strike) => {
                setWorkingPrice(strike);
              } } ref={smileRef} />
          </div>
        </Col>
        </Row>
      </Col>
    </Row>

    <Row>
      <Col>
        {error}
      </Col>
    </Row>
    <Row style={{textAlign: 'left', marginTop:'20px'}}>
      <Col>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "left"}}>
          <div style={{width:"35%", marginTop:'5px'}}>
          <Dropdown  label='Exercise:' elementName='ExerciseType' options={exerciseTypes} selected_value={exerciseType}
              onValueChange={ ( element, selected_option ) => {
                  if ( selected_option !== exerciseType )
                  {
                    setPricingModel(undefined);
                    setExerciseType(selected_option);
                  }
                } }/>
              </div>
            <div style={{width:"65%", marginTop:'5px'}}>
              <Dropdown label='Model:' elementName='PricingModel' options={pricingModels} selected_value={pricingModel}
                onValueChange={ ( element, selected_option ) => { setPricingModel(selected_option); } }/>
            </div>
          </div>
        <div style={greeksDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
        <Row>
          <Col>
            <GreeksPanel volData={volGreekData} workingPrice={workingPrice} marketPrice={activeStockTicker} selectedStrike={selectedStrike}
              ref={greeksPanelRef} strikeSelectedCallback={ (strikeWithGreeks) => { setSelectedStrikeWithGreeks(strikeWithGreeks);} }/>
          </Col>
          </Row>
          <Row  style={{textAlign:'left'}}>
          <Col>
          <div className="main--sub--title">
            Pricer: {pricerURL} -> Latency: {latency}ms
          </div>
          </Col>
          </Row>
        </div>
        </Col>
      <Col>
        <Row>
          <SingleStrikePricer strikeWithGreeks = {selectedStrikeWithGreeks} exerciseType = {exerciseType} pricingModel = {pricingModel} strikeSelectedCallback={ (pricedStrikeWithGreeks) => { setPricedStrikeWithGreeks(pricedStrikeWithGreeks);} } />
        </Row>
        <Row>
          <div style={ ( userCredentials == undefined ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
              <Ticket ticker={activeStockTicker} positions={positions} userCredentials={logonResponse}
                      pricedStrikeWithGreeks = {pricedStrikeWithGreeks}
                      exerciseType = {exerciseType} tradeBookCallback={(response)=>{ setTradeBooked(response); }}/>
          </div>
        </Row>
      </Col>
    </Row>
    <div style={{paddingTop:'10px'}}>
    <Row>
      <Col>
        <div style={ ( userCredentials == undefined ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
          <PositionsPanel positions={positions} marketData={marketData} volData={interpolVolData} valuationDate={valuationDate}
            europeanPricingModel={{value:'ADE'}} americanPricingModel={{value:'CRR'}} userCredentials={userCredentials}
            setExposure={ (exposure) => {
              setExposure(exposure);
            } }/>
        </div>
      </Col>
      <Col>
        <div style={ ( userCredentials == undefined ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
          <ExposurePanel exposure={exposure}/>
        </div>
      </Col>
    </Row>
    </div>
  </Container>
  </div>
  </div>
  );
}

export default App;
