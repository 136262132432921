/*  This file is part of ql_rest, a free-software/open-source library
    for utilization of QuantLib over REST */

import { compareAsc, format, parseISO } from 'date-fns'

const QuantLibHelper = {

  //get_option_termstructure: function(request_id, type, underlying_price, strike, vol, div_rate, risk_free_rate)
  get_option_termstructure: function(exerciseType, option_details)
  {
    var option_ts = {}

    var request_id = option_details['type'] + '/' + option_details['request_id']

    option_ts['symbol'] = option_details['symbol']
    option_ts['underlying_symbol'] = option_details['underlying_symbol']

    option_ts['qlStrikedTypePayoff'] = {}
    option_ts['qlBlackConstantVol'] = {}
    option_ts['qlGeneralizedBlackScholesProcess'] = {}

    option_ts['qlStrikedTypePayoff']['Strike'] = option_details['Strike']
    option_ts['qlStrikedTypePayoff']['ObjectId'] = option_details['request_id'] + '/qlStrikedTypePayoff' + '/' + option_ts['qlStrikedTypePayoff']['Strike']
    option_ts['qlStrikedTypePayoff']['PayoffID'] = 'Vanilla'
    option_ts['qlStrikedTypePayoff']['OptionType'] = option_details['OptionType']
    option_ts['qlStrikedTypePayoff']['ThirdParameter'] = 0.0
    option_ts['qlStrikedTypePayoff']['Permanent'] = false
    option_ts['qlStrikedTypePayoff']['Trigger'] = false
    option_ts['qlStrikedTypePayoff']['Overwrite'] = true

    option_ts['qlBlackConstantVol']['DayCounter'] = 'Actual/365 (Fixed)'
    option_ts['qlBlackConstantVol']['Calendar'] = 'TARGET'
    option_ts['qlBlackConstantVol']['SettlementDate'] = ''
    option_ts['qlBlackConstantVol']['ThirdParameter'] = 0.0
    option_ts['qlBlackConstantVol']['Permanent'] = false
    option_ts['qlBlackConstantVol']['Trigger'] = false
    option_ts['qlBlackConstantVol']['Overwrite'] = true

    option_ts['qlGeneralizedBlackScholesProcess']['DayCounter'] = option_ts['qlBlackConstantVol']['DayCounter']
    option_ts['qlGeneralizedBlackScholesProcess']['SettlementDate'] = ''
    option_ts['qlGeneralizedBlackScholesProcess']['Underlying'] = option_details['Underlying']
    option_ts['qlGeneralizedBlackScholesProcess']['RiskFreeRate'] = option_details['RiskFreeRate']

    if (option_details['div_rate'] != undefined)
      option_ts['qlGeneralizedBlackScholesProcess']['DividendYield'] = (parseFloat(option_details['div_rate'])/option_details['Underlying'])
    else
      option_ts['qlGeneralizedBlackScholesProcess']['DividendYield'] = 0.0

    option_ts['qlGeneralizedBlackScholesProcess']['Permanent'] = false
    option_ts['qlGeneralizedBlackScholesProcess']['Trigger'] = false
    option_ts['qlGeneralizedBlackScholesProcess']['Overwrite'] = true

    option_ts['qlBlackConstantVol']['ObjectId'] = option_details['request_id'] + '/qlBlackConstantVol/' + option_ts['qlStrikedTypePayoff']['Strike'] + '/' + option_ts['qlStrikedTypePayoff']['OptionType']
    option_ts['qlBlackConstantVol']['Volatility'] = option_details['vol']/100.0

    option_ts['qlGeneralizedBlackScholesProcess']['ObjectId'] = option_details['request_id'] +'/qlGeneralizedBlackScholesProcess/' + option_ts['qlBlackConstantVol']['ObjectId']
    option_ts['qlGeneralizedBlackScholesProcess']['BlackVolID'] = option_ts['qlBlackConstantVol']['ObjectId']

    option_ts['exercise'] = {}
    option_ts['exercise']['ObjectId'] = option_details['request_id'] + '/exercise' + '/' + option_ts['qlStrikedTypePayoff']['Strike'] + '/' + option_ts['qlStrikedTypePayoff']['OptionType']
    option_ts['exercise']['exerciseType'] = exerciseType
    option_ts['exercise']['Overwrite'] = true

    option_ts['exercise']['expiry_date_vol'] = option_details['expiry_date_vol']

    if (exerciseType == 'American')
    {
       option_ts['exercise']['EarliestDate'] = option_details['valuationDate']
       option_ts['exercise']['LatestDate'] = option_details['expiry_date_iso']
       option_ts['exercise']['PayoffAtExpiry'] = false;

       option_ts['qlBinomialPricingEngine'] = {}
       option_ts['qlBinomialPricingEngine']['ObjectId'] = 'Engine'
       option_ts['qlBinomialPricingEngine']['EngineID'] = option_details['model']
       option_ts['qlBinomialPricingEngine']['TimeSteps'] = 200
       option_ts['qlBinomialPricingEngine']['Permanent'] = false
       option_ts['qlBinomialPricingEngine']['Trigger'] = false
       option_ts['qlBinomialPricingEngine']['Overwrite'] = true
    }
    else
    {
      option_ts['qlPricingEngine'] = {}
      option_ts['qlPricingEngine']['ObjectId'] = 'Engine'
      option_ts['qlPricingEngine']['EngineID'] = option_details['model']
      option_ts['qlPricingEngine']['Permanent'] = false
      option_ts['qlPricingEngine']['Trigger'] = false
      option_ts['qlPricingEngine']['Overwrite'] = true

      option_ts['exercise']['ExpiryDate'] = option_details['expiry_date_iso']
    }

    option_ts['exercise']['Permanent'] = false
    option_ts['exercise']['Trigger'] = false
    option_ts['exercise']['Overwrite'] = false


    return option_ts

  },

  get_interpolation_request_for_exercise: function(request_id, exercise_date, vols, interpolType)
  {
    var strikes = [];

    var call_strikes = []
    var call_vols = []
    var put_strikes = []
    var put_vols = []

    var calls_in = vols['calls']
    var puts_in = vols['puts']

    Object.keys(calls_in).forEach(strike => {

      if (!strikes.includes(strike))
        strikes.push(parseFloat(strike));

      var vol_float = parseFloat(calls_in[strike])
      if ( vol_float > 0 && vol_float < 1000 )
      {
        call_strikes.push(parseFloat(strike));
        call_vols.push(vol_float);
      }
    });

    Object.keys(puts_in).forEach(strike => {

      if (!strikes.includes(strike))
        strikes.push(parseFloat(strike));

      var vol_float = parseFloat(puts_in[strike])
      if ( vol_float > 0 && vol_float < 1000 )
      {
        put_strikes.push(parseFloat(strike));
        put_vols.push(vol_float);
      }
    });

    /*if ('calls' in vols)
    {
      vols['calls'].forEach((item, i) => {

      });

    }*/
    /*
    vols.forEach(function (item, index) {
      console.log(item, index);
      strikes.push(item['Strike']);

      if ('call_vol' in item )
      {
        var vol_float = parseFloat(item['call_vol'])

        if ( vol_float > 0 && vol_float < 1000 )
        {
          call_strikes.push(item['Strike'])
          call_vols.push(item['call_vol'])
        }
      }

      if ('put_vol' in item )
      {
        var vol_float = parseFloat(item['put_vol'])

        if ( vol_float > 0 && vol_float < 1000 )
        {
          put_strikes.push(item['Strike'])
          put_vols.push(item['put_vol'])
        }
      }
    });*/

    var interpolation_request = {}

    interpolation_request['call_vols'] = {};
    interpolation_request['call_vols']['qlInterpolation'] = {}
    interpolation_request['call_vols']['qlInterpolation']['ObjectId'] = request_id + '/' + exercise_date+ '/qlInterpolation' + '/' + 'calls'
    interpolation_request['call_vols']['qlInterpolation']['InterpolationType'] = interpolType
    interpolation_request['call_vols']['qlInterpolation']['XArray'] = call_strikes
    interpolation_request['call_vols']['qlInterpolation']['YArray'] = call_vols
    interpolation_request['call_vols']['qlInterpolation']['Permanent'] = false
    interpolation_request['call_vols']['qlInterpolation']['Trigger'] = false
    interpolation_request['call_vols']['qlInterpolation']['Overwrite'] = false

    interpolation_request['put_vols'] = {};
    interpolation_request['put_vols']['qlInterpolation'] = {}
    interpolation_request['put_vols']['qlInterpolation']['ObjectId'] = request_id + '/' + exercise_date + '/qlInterpolation' + '/' + 'puts'
    interpolation_request['put_vols']['qlInterpolation']['InterpolationType'] = interpolType
    interpolation_request['put_vols']['qlInterpolation']['XArray'] = put_strikes
    interpolation_request['put_vols']['qlInterpolation']['YArray'] = put_vols
    interpolation_request['put_vols']['qlInterpolation']['Permanent'] = false
    interpolation_request['put_vols']['qlInterpolation']['Trigger'] = false
    interpolation_request['put_vols']['qlInterpolation']['Overwrite'] = false

    interpolation_request['strikes'] = strikes

    return interpolation_request
  },

  get_interpolation_request: function(request_id, vols_in, interpolType)
  {
    var interpol_request_out = {};

    Object.keys(vols_in).forEach(exercise_date => {

      var vols = vols_in[exercise_date];

      var vols_per_excercise = QuantLibHelper.get_interpolation_request_for_exercise(request_id, exercise_date,  vols, interpolType);
      interpol_request_out[exercise_date] = vols_per_excercise;

    });

    return interpol_request_out;
  },

};

export default QuantLibHelper;
