/*  This file is part of ql_rest, a free-software/open-source library
    for utilization of QuantLib over REST */
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import LabeledNumericInput from './LabeledNumericInput'

import TradeCaptureHelper from '../helpers/TradeCaptureHelper'
import SegmentedControl from './SegmentedControl'

import React from 'react'
import { useEffect, useState, useRef } from 'react';
import { compareAsc, format, parseISO } from 'date-fns'

const Ticket = React.forwardRef ((props, ref) => {

  const [price, setPrice] = useState();
  const [instrument, setInstrument] = useState({'symbol':'','name':':'});
  const [instrumentType, setInstrumentType] = useState('stock');
  const [optionType, setOptionType] = useState('call');
  const [quantity, setQuantity] = useState();
  const [quantityLabel, setQuantityLabel] = useState('Quantity:');
  const [quantityIncrement, setQuantityIncrement] = useState();
  const [ticketDisabled, setTicketDisabled] = useState();

  useEffect(() =>
  {
    if ( props.ticker === undefined || props.pricedStrikeWithGreeks === undefined || props.exerciseType === undefined )
      return;

    var symbol = props.ticker.Symbol

    var instrument = {}

    if ( instrumentType === 'stock' )
    {
      instrument['symbol'] = props.ticker.Symbol
      instrument['name'] = props.ticker.Name
      setPrice(props.ticker.Price)
      setQuantity(100)
      setQuantityIncrement(100)
      setQuantityLabel('Quantity:')
    } else {
      setQuantityLabel('Contracts:')
      instrument = TradeCaptureHelper.compose_option_instrument(optionType, symbol, props.pricedStrikeWithGreeks['strike_with_greeks'])
      setQuantity(1)
      setQuantityIncrement(1)
      var current_price = props.pricedStrikeWithGreeks[0];
      setPrice(current_price[optionType]);
    }

    instrument['type'] = instrumentType
    setInstrument(instrument)

  }, [props, instrumentType, optionType]);

  const option_type_segment  = <SegmentedControl name="group-2" callback={(val) => setOptionType(val)} controlRef={useRef()} segments={[{label: "Call",value: "call",ref: useRef()}, {label: "Put",value: "put",ref: useRef()}]}/>;

  const tradeBookingDone = (response) => {
      props.tradeBookCallback(response)
      setTicketDisabled(false)
  };

  return (

      <Container style={ticketDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
        <Row>
          <Col style={{textAlign: 'left'}}>
            <h6>
              Trade
            </h6>
            </Col>
            <Col>
              <SegmentedControl name="group-1" callback={(val) => {{
                  setInstrument({'symbol':'','name':':'});
                  setInstrumentType(val);
                }}} controlRef={useRef()} segments={[{label: "Stock",value: "stock",ref: useRef()}, {label: "Option",value: "option",ref: useRef()}]}/>
            </Col>
            <Col>
            <div>
            {
              instrumentType == 'option' ? (option_type_segment) : null
            }
            </div>
            </Col>

        </Row>
        <Row style={{paddingTop:'10px'}}>
          <Col xs={4}>
            <Row>
              <Col style={{textAlign: 'left'}}>
                {
                  instrumentType == 'stock' ?
                 ( <Row>
                  <Col xs={2}>
                  <b>
                    {instrument.symbol}
                  </b>
                  </Col>
                  <Col  xs={4}>
                    {instrument.name}
                  </Col>
                </Row> ) :
                (
                  <Row>
                   <Col>
                    <b>
                     {instrument.name}
                    </b>
                   </Col>
                 </Row>
                )
                }
              </Col>
            </Row>
          </Col>
          <Col xs={7}>
          <Row>
            <Col style={{justifyContent:'center', alignItems:'center', display: 'flex'}}>
              <Row>
                <Col>
                  <LabeledNumericInput label="Price:" value={price} elementName="price" width="80" onChange={(elementName, new_value)=>{
                    setPrice(parseFloat(new_value));
                  }} onChanged= {(elementName, new_value)=>{ /*setGreeksDisabled(true);*/ }} postfix='' step={0.50}/>
                </Col>
                <Col>
                  <LabeledNumericInput label={quantityLabel} value={quantity} elementName="quantity" width="60" onChange={(elementName, new_value)=>{
                    setQuantity(parseInt(new_value));
                  }} onChanged= {(elementName, new_value)=>{ /*setGreeksDisabled(true);*/ }} postfix='' step={quantityIncrement}/>
                </Col>
              </Row>
            </Col>
            <Col  xs={4}  style={{justifyContent:'right', alignItems:'right', display: 'flex'}}>
              <Row>
              <Col  style={{justifyContent:'right', alignItems:'right', display: 'flex'}}>
            <Button size="sm" onClick={()=> {
                setTicketDisabled(true)
                TradeCaptureHelper.submit_trade(props.userCredentials, props.positions, instrument, 'buy', price, quantity, tradeBookingDone)
            }}>Buy</Button>
          </Col>
          <Col>
            <Button size="sm" onClick={()=> {
                  setTicketDisabled(true)
                  TradeCaptureHelper.submit_trade(props.userCredentials, props.positions, instrument, 'sell', price, quantity, tradeBookingDone);
              }}>Sell</Button>
              </Col>
              </Row>
          </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
 });

export default Ticket;
